import { ExperienceCardProps } from '../../../components'
import { pontoon } from './30apontoon'
import { yacht } from './30ayachtcharters'
import { beachtobayoutdoors } from './beachtobayoutdoors'
import { beyondbeauty } from './beyondbeauty'
import { cruiser30a } from './cruiser30a'
import { destincars } from './destincars'
import { destinwakesurf } from './destinwakesurf'
import { familyphotography30a } from './familyphotography30a'

export * from './30apontoon'
export * from './30ayachtcharters'
export * from './beachtobayoutdoors'
export * from './beyondbeauty'
export * from './cruiser30a'
export * from './destincars'
export * from './destinwakesurf'
export * from './familyphotography30a'

export const experiences: ExperienceCardProps[] = [
  pontoon,
  yacht,
  cruiser30a,
  destincars,
  beyondbeauty,
  beachtobayoutdoors,
  familyphotography30a,
  destinwakesurf
]