import { Grid } from "@mui/material"
import React from "react"
import { useParams } from "react-router-dom"

import { useExperiences } from "../../hooks"
import { ExperienceCard, ExperienceCardProps }from '../ExperienceCard'

export interface ExperienceGalleryProps {
  count?: number
}

export const ExperienceGallery: React.FC<ExperienceGalleryProps> = ({ count }) => {
  const category = useParams<{ category?: string }>()?.category
  const cardData: ExperienceCardProps[] = useExperiences(count, category)
  return(
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, md:6, lg:12 }} alignItems="stretch">
      {cardData.map((cardData, index) => (
        <Grid item key={index} xs={3}>
          <ExperienceCard {...cardData}/>
        </Grid>
      ))}
    </Grid>
  )
}

export const ExperienceGalleryFactory = (props: ExperienceGalleryProps) => {
  return <ExperienceGallery {...props}/>
}