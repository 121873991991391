import { createTheme } from "@mui/material/styles"

// /* CSS HEX */
// --asparagus: #88ab75ff;
// --black-olive: #3d3b30ff;
// --harvest-gold: #eca400ff;
// --imperial-red: #e54b4bff;
// --seashell: #f7ebe8ff;

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: "#88ab75ff",
      light:"#88ab75ff",
      dark:"#88ab75ff",
    },
    secondary: {
      main: "#eca400ff",
    },
    background:{
      default: "#88ab75ff",
    },
    error: {
      main: "#e54b4bff",
    },
  },
})

