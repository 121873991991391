import { ExperienceCardProps } from "../components"
import { experiences } from "./common/experiences"

function getRandomSubset<T>(arr: T[], count: number): T[] {
  const shuffled = [ ...arr ].sort(() => 0.5 - Math.random())
  return shuffled.slice(0, count)
}

export const useExperiences = (count = 12, category?: string): ExperienceCardProps[] => {
  const options = (category) ? experiences.filter((experience) => experience.category === category):experiences
  return getRandomSubset(options, count)
}