import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { ExperienceCategory } from '../../model'

export interface ExperienceCardProps {
  // TODO: Support multiple categories with primary category
  category: ExperienceCategory
  description: string
  email: string
  id: string,
  images: string[],
  phone?: string
  title: string,
  website: string
}
  
export const ExperienceCard: React.FC<ExperienceCardProps> = ({ description, images, title, id }: ExperienceCardProps) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea component={Link} to={`/experiences/${id}`}>
        <CardMedia component="img" height="140" width="200" image={`${process.env.PUBLIC_URL}/${images[0]}`} alt="green iguana"/>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* Can do pop-out or focus here for details inline */}
      {/* <CardActions>
        <Button size="small" color="primary" component={Link} to={`/experiences/${1}`}>
            Details
        </Button>
      </CardActions> */}
    </Card>
  )
}