import "./index.css"

import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import { App } from "./App"
import { ExperienceDetails, ExperienceGallery, Main } from "./components"
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const router = createBrowserRouter([
  {
    path: "/", element: <App />, children: [
      {
        path: "/",
        element: <Main />,
        
      },
      {
        path: "experiences/:id",
        element: <ExperienceDetails />,
      },
      {
        path: "categories/:category",
        element: <ExperienceGallery count={3}/>,
      }
    ]
  },
])

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
