import EmailIcon from '@mui/icons-material/Email'
import LinkIcon from '@mui/icons-material/Link'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { Button, Grid, IconButton, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { useExperience } from '../../hooks'

export const ExperienceDetails: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  if (!id) {
    return (
      <Button size="small" color="primary" onClick={() => {navigate(-1)}}>
        Back
      </Button>
    )
  }
  const experience = useExperience(id)
  if (!experience) return null
  const { title, description, images } = experience
  const featured = [ images[0] ]
  const regular = images.slice(featured.length)
  return (
    <div>
      <Button size="small" color="primary" onClick={() => {navigate(-1)}}>
        Back
      </Button>
      <Grid container>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" >
          {featured.map(image => (
            <Grid item key={image} xs={ 12 } justifyContent="center" alignItems="left">
              <img height="280" width="400" src={`${process.env.PUBLIC_URL}/${image}`}/>
            </Grid>
          ))}
        </Grid>
        {regular.map(image => (
          <Grid item key={image} xs={ "auto" } justifyContent="center" alignItems="left">
            <img height="140" width="200" src={`${process.env.PUBLIC_URL}/${image}`}/>
          </Grid>
        ))}
        <Grid container direction="row" justifyContent="left" alignItems="stretch">
          <Grid item xs={8}>
            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>{title}</Typography>
          </Grid>
          <Grid item xs={8}>
            <IconButton onClick={() => { window.open(experience.website, '_blank') }} aria-label="delete">
              <LinkIcon />
            </IconButton>
            <IconButton onClick={() => { window.open(`mailto:${experience.email}`, '_blank') }} aria-label="delete">
              <EmailIcon />
            </IconButton>
            {/* TODO: Handle missing */}
            <IconButton onClick={() => { window.open(`tel:${experience.phone}`, '_blank') }} aria-label="delete">
              <LocalPhoneIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={{ mt: 2, mb: 1 }}>{description}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Button size="small" color="primary" onClick={() => {navigate(-1)}}>
        Back
      </Button> */}
    </div>
  )
}