
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import DeckIcon from '@mui/icons-material/Deck'
import DiamondIcon from '@mui/icons-material/Diamond'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw'
import GolfCourseIcon from '@mui/icons-material/GolfCourse'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import MailIcon from '@mui/icons-material/Mail'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import SailingIcon from '@mui/icons-material/Sailing'
import SetMealIcon from '@mui/icons-material/SetMeal'
import StorefrontIcon from '@mui/icons-material/Storefront'
import TapasIcon from '@mui/icons-material/Tapas'

import { ExperienceCategory } from '../../model'


export interface AccordionItem {
  icon: React.ElementType, 
  name: string,
  category: ExperienceCategory,
}

export const entertainmentItems: AccordionItem[] = [
  {
    name: "Bonfires",
    category: "bonfires",
    icon: LocalFireDepartmentIcon
  },
  // {
  //   name: "Entertainment",
  //   path: "entertainment",
  //   // icon: ConfirmationNumberIcon
  //   icon: LocalActivityIcon
  // },
  {
    name: "Go-Karts",
    category: "rides",
    icon: MailIcon
  },
  {
    name: "Putt Putt",
    category: "golf",
    icon: GolfCourseIcon
  },

  {
    name: "Photography",
    category: "photography",
    icon: PhotoCameraIcon
  },

]

export const foodItems: AccordionItem[] = [
  {
    name: "Restaurants",
    category: "restaurants",
    icon: RestaurantIcon
  },
  {
    name: "Seafood",
    category: "seafood",
    icon: SetMealIcon
  },
  {
    name: "Catering",
    category: "catering",
    icon: TapasIcon
  },
]
export const rentalItems: AccordionItem[] = [
  {
    name: "Boats",
    category: "boats",
    icon: SailingIcon
  },

  {
    name: "Bikes",
    category: "bikes",
    icon: DirectionsBikeIcon
  },
  {
    name: "Golf Carts",
    category: "electric",
    icon: ElectricRickshawIcon
  },
  {
    name: "Beach Chairs",
    category: "equipment",
    icon: DeckIcon
  },
]
export const shoppingItems: AccordionItem[] = [
  {
    name: "Jewelry",
    category: "jewelry",
    icon: DiamondIcon
  },
  {
    name: "Beach",
    category: "beachstores",
    icon: BeachAccessIcon
  },
  {
    name: "Boutiques",
    category: "boutiques",
    icon: StorefrontIcon
  },
]