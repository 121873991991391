import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import * as React from "react"
import { Outlet } from "react-router-dom"

import { Copyright, ResponsiveDrawer } from "./components"

export const App = () => {

  return (
    <Container maxWidth={false}>
      <Box sx={{ my: 4 }}>
        <ResponsiveDrawer>
          <div id="detail">
            <Outlet />
          </div>
        </ResponsiveDrawer>
        <Copyright />
      </Box>
    </Container>
  )
}
