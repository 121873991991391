import { Grid, ThemeProvider, Typography } from "@mui/material"

import { theme } from "../theme"
import { FeaturedExperiences } from "./FeaturedExperiences"

export const Main = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container columns={1} alignItems="stretch">
        <Grid item key={1}>
          <video loop={true} muted={true} autoPlay={true} src={`${process.env.PUBLIC_URL}/banner-video-placeholder.mp4`} />  
        </Grid>
        <Grid item key={2}>
          <Typography>
            Welcome to Panhandle Bucks, your insider's guide to the extraordinary experiences awaiting you in the Florida Panhandle. We've curated
            handpicked adventures, local gems, and sun-soaked serenity to enrich the holiday of every vacation-goer, offering a new perspective on
            this iconic stretch of Florida's coastline. Embrace the charm of the Panhandle, where emerald waves meet sugar-white sands, where
            vibrant local culture blends with breathtaking natural beauty, and where every sunset is the beginning of a unique beachside memory
            you'll cherish forever.
          </Typography>
        </Grid>
        <Grid item key={3}>
          <FeaturedExperiences/>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}