import { ExperienceCardProps } from "../../../../components"

export const destincars: ExperienceCardProps = {
  category: "rides",
  description: "Welcome to the new age of renting a car while on vacation! Destin Cars is your local concierge style car rental service. Destin Cars makes it FAST and EASY to go directly from the plane to your car to your vacation! No paperwork, no standing in line, and no longer being told your reserved car isn't available or even worse…they're sold out of cars completely. With our service, you pick out your exact car that will be delivered to you directly at the airport, ready to go immediately. We also offer very custom and desirable cars for you to choose from such as a 15 passenger Mercedes Sprinter van, lifted Ford Bronco, or we also have economy cars and minivans to fit all price points. 100% of our customers have given us 5 star reviews, come see why for yourself at DestinCars.com!",
  email: "destincars@gmail.com",
  id: '1ec58f9f-0cfb-447b-b87a-959cb15a5fd6',
  images: [
    "experiences/destincars/logo.jpg",
    "experiences/destincars/jeep1.jpg",
    "experiences/destincars/jeep2.jpg",
    "experiences/destincars/mini.jpg",
    "experiences/destincars/suv1.jpg",
    "experiences/destincars/suv2.jpg",
    "experiences/destincars/suv3.jpg",
    "experiences/destincars/van1.jpg",
    "experiences/destincars/van2.jpg",
  ],
  phone: "314-753-6555",
  title: "DestinCars.com",
  website: "https://destincars.com",
}