import { Typography } from "@mui/material"

import { ExperienceGallery } from "../ExperienceGallery"

export const FeaturedExperiences = () => {
  return (
    <div>
      <Typography variant="h3" component="h1">
        Featured Experiences
      </Typography>
      <Typography>
        Get started exploring the true essence of the Florida Panhandle with our handpicked list of standout experiences. These captivating
        adventures, ranging from pristine beaches to rich local culture, provide an unforgettable introduction to this coastal gem. Embark
        on a journey, not just a vacation, packed with unique moments under the Florida sun.
      </Typography>
      <ExperienceGallery count={24}/>
    </div>
  )
}