import { ExperienceCardProps } from "../../../../components"

export const destinwakesurf: ExperienceCardProps = {
  category: "boats",
  description: "Similar to surfing an ocean wave, wakesurfing is just riding a wave that the boat creates, but it's much easier to catch and control. Wakesurfing is a great way to get out on the water and try something new, or if you're an old pro, get back out there and show off your stuff. We have taught ages 71 to 7. Our success rate of 96% means almost anyone can do it! During your wakesurfing experience you will be given customized  instruction, top of the line equipment, and USCG approved safety gear. Ease into some fun with an expert crew who will ensure you have the opportunity to ride a continuous wave and experience the thrill of surfing. Enjoy a day of adventure, fun, and excitement on the water in Destin, Florida!",
  email: "tara@destinwakesurf.com",
  id: 'dab8eaa2-12d9-4aec-bf22-384b72bbf14f',
  images: [
    "experiences/destinwakesurf/surf1.jpg",
    "experiences/destinwakesurf/surf2.jpg",
    "experiences/destinwakesurf/surf3.jpg",
    "experiences/destinwakesurf/surf4.jpg",
    "experiences/destinwakesurf/surf5.jpg",
  ],
  phone: "850-714-1677",
  title: "Destin Wakesurf",
  website: "https://www.destinwakesurf.com",
}