import { ExperienceCardProps } from "../../../../components"

export const beachtobayoutdoors: ExperienceCardProps = {
  category: "bonfires",
  description: "Introducing Beach to Bay Outdoors: Your Ultimate Outdoor Experience! Looking to make your beach vacation or stay-cation an unforgettable adventure? Look no further than Beach to Bay Outdoors! Nestled on the picturesque white sandy beaches of the Emerald Coast of Florida, we offer a range of thrilling activities and services that will ensure you have the time of your life. Join us for mesmerizing Movie Nights under the starry sky, where you can relax on comfortable loungers and enjoy blockbuster films with your loved ones. Immerse yourself in the magic of cinema while the gentle waves serenade you in the background. For those seeking an adrenaline rush, our exhilarating Slingshots are at your disposal. Experience the freedom of the open road as you cruise along the coast, feeling the wind in your hair and the sun on your face. Get ready for a heart-pounding journey that will leave you exploring vibrant beaches, shops or restaurants. The possibilities are endless! Embrace the future of transportation with KAASPEED E-Scooters. Zip around the beachfront with ease and style, enjoying the scenic views and the eco-friendly perks of electric mobility. Feel the freedom of movement and experience the joy of exploration like never before. Booking your ultimate outdoor experience has never been easier! Simply visit our user-friendly website today and browse through our wide range of offerings. From movie nights to Slingshots, togoboats to KAASPEED E-Scooters, we have everything you need to make your vacation a hit! Don't miss out on the adventure of a lifetime. Visit Beach to Bay Outdoors today and let us transform your beach getaway into an unforgettable journey. Book now and get ready to create memories that will last a lifetime.",
  email: "patrick.hancock@beachtobayoutdoors.com ",
  id: '19e9af9a-2696-4f7f-8e81-e51d0a975341',
  images: [
    "experiences/beachtobayoutdoors/movies.webp",
    "experiences/beachtobayoutdoors/car.webp",
    "experiences/beachtobayoutdoors/chair.webp",
    "experiences/beachtobayoutdoors/road.webp",
    "experiences/beachtobayoutdoors/scooter.webp",
    "experiences/beachtobayoutdoors/togoboat.webp",
  ],
  phone: "850-533-7391",
  title: "Beach to Bay Outdoors",
  website: "https://www.beachtobayoutdoors.com",
}