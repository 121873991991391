import { ExperienceCardProps } from "../../../../components"

export const pontoon: ExperienceCardProps = {
  category: "boats",
  description: "30a Pontoon Rental in Santa Rosa Beach offers Premier Luxury Pontoon Rentals.  Our pontoon rentals are 150, 200, and 350 hp 28 foot boats as well as 150 and 200 hp double decker tritoons with water slides.  These are the smoothest, fastest, and most luxurious pontoon rentals in the state. We also offer a 400 hp power boat.    In addition, we offer 52-75 Foot Luxury Yacht Charters, Deep Sea Fishing Charters, and Inshore Fishing Charters.",
  email: "30arentalcompany@gmail.com",
  id: '9d4367ac-13f3-45ee-b581-8b6877bacd88',
  images: [
    "experiences/30apontoon/pontoon-rental2.jpg",
    "experiences/30apontoon/fishing-charter.jpg",
    "experiences/30apontoon/fishing-charter2.jpg",
    "experiences/30apontoon/pontoon-rental.jpg",
    "experiences/30apontoon/yacht-charter.jpg",
    "experiences/30apontoon/yacht-charter2.jpg",
  ],
  phone: "850-220-6400",
  title: "30a Pontoon Rental",
  website: "https://www.30apontoon.com",
}