import { ExperienceCardProps } from "../../../../components"

export const familyphotography30a: ExperienceCardProps = {
  category: "photography",
  description: "Family Photography 30A is a hip and happening portrait photography service that specializes in capturing the beautiful moments of families. Well-known for his 5-star reputation, Guillaume, the French photographer who moved to the Panhandle a few years ago, will ensure that you and your family have the best experience while capturing stunning images and candid family moments. Looking for affordable and extremely professional Family Photography in 30A and PCB? Look no further! They serve the entire area all year round and come straight to your beach for your session. Secure your spot now with their convenient Online Booking available on the website.",
  email: "familyphotography30a@gmail.com",
  id: '0c538657-c1fc-4628-ac03-8106809b937e',
  images: [
    "experiences/familyphotography30a/family1.jpg",
    "experiences/familyphotography30a/family2.jpg",
    "experiences/familyphotography30a/family3.jpg",
    "experiences/familyphotography30a/family4.jpg",
    "experiences/familyphotography30a/family5.jpg",
    "experiences/familyphotography30a/family6.jpg",
  ],
  title: "Family Photography 30A",
  website: "https://www.familyphotography30a.com/",
}