import { ExperienceCardProps } from "../../../../components"

export const beyondbeauty: ExperienceCardProps = {
  category: "boutiques",
  description: "With a long career in Advanced Hair Education, Maureen Walker is one of Destin Florida's most sought out stylists. She has been studying and working for some of America's top salons for over 15 years. After 16 years of Education and Business Development with Aveda's Top Salons, Maureen has developed her advanced skillset allowing her to stand firm in her work. As continuing education is a top priority for Maureen, she values continuing her education. One of Maureen's strongest talents is in color corrections, even difficult and complicated ones. She uses her color theory to accurately map out a solution to any problem her client may be facing with their hair color. She enjoys creativity, and serving her guests with optimal services.",
  email: "withinariix@gmail.com",
  id: '83be2acf-8b23-4ce9-896b-7b6fbf497135',
  images: [
    "experiences/beyondbeauty/hair1.jpg",
    "experiences/beyondbeauty/hair2.jpg",
    "experiences/beyondbeauty/hair3.jpg",
    "experiences/beyondbeauty/hair4.jpg",
    "experiences/beyondbeauty/hair6.jpg",
    "experiences/beyondbeauty/hair7.jpg",
  ],
  phone: "850-225-5094",
  title: "Beyond Beauty",
  website: "https://www.thebeyondbeautystore.com",
}